import React, { useState, useEffect } from 'react';

const SoccerGame = () => {
  const [position, setPosition] = useState({ x: 285, y: 185 });

  useEffect(() => {
    const handleKeyDown = (e) => {
      const { key } = e;
      const step = 10;

      setPosition((prev) => {
        let { x, y } = prev;

        switch (key) {
          case 'ArrowUp':
            y = Math.max(0, y - step);
            break;
          case 'ArrowDown':
            y = Math.min(370, y + step);
            break;
          case 'ArrowLeft':
            x = Math.max(0, x - step);
            break;
          case 'ArrowRight':
            x = Math.min(570, x + step);
            break;
          default:
            return prev;
        }

        return { x, y };
      });
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  return (
    <div>
      <div
        style={{
          width: '600px',
          height: '400px',
          backgroundColor: '#4CAF50',
          position: 'relative',
          border: '2px solid white',
        }}
      >
        <div
          style={{
            width: '30px',
            height: '30px',
            backgroundColor: 'white',
            borderRadius: '50%',
            position: 'absolute',
            top: `${position.y}px`,
            left: `${position.x}px`,
          }}
        />
      </div>
      <p>Use arrow keys to move the ball</p>
    </div>
  );
};
function App() {
  return (
    <div className="App">
      <h1>Soccer Game</h1>
      <SoccerGame />
    </div>
  );
}

export default App;